<template lang="pug" >
  .auth-layout
    <router-view/> 
</template>
<script>

export default {
  name: 'AuthLayout'
}
</script>

<style lang="scss">
  .auth-layout{padding-top: 30px;}
</style>
